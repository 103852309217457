.FindItem {
  width: 100%;
  height: 64px;
  background: #fff;
  display: flex;
  gap: 20px;
  align-items: center;
  color: #212121;

  .image {
    margin-left: 6px;
    display: flex;
    width: 56px;

    img {
      margin: auto;
      max-height: 40px;
    }
  }

  &:hover {
    background: #EEE;
  }
}
