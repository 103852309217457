.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: var(--secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
