.selector {
  height: 40px;
  border-radius: 12px;
  border: 1px solid #C4C7CF;
  background: #FFF;
  padding: 0 12px;
  outline: none;

  &:focus {
    border: 1px solid #008FF8;
    box-shadow: 0 0 0 3px rgba(0, 143, 248, 0.20);
  }
}

.payment_method {
  width: 36px;
  height: 36px;
  margin: 0 10px;
  align-items: center;
  display: flex;
}

.payment_method_wrapp {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
