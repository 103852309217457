.CardPage {
  width: 90%;
  margin: 0 auto;
  margin-top: 80px;

  h2 {
    color: #212121;
    font-size: 32px;
    font-weight: 700;
    line-height: 117%;
    margin: 24px 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  max-width: 400px;

  p {
    line-height: 16px;
    margin-left: 8px;
    font-size: 16px;
    color: rgb(145, 151, 161);
  }
}

.field {
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    line-height: 16px;
    margin-left: 8px;
    font-size: 16px;
    color: rgb(158, 165, 177);
  }
}

.resultText {
  line-height: 16px;
  margin-left: 8px;
  font-size: 16px;
}

.input {
  padding: 8px;
  border-bottom: 1px solid rgb(222, 222, 222);
  background: transparent;
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
}

.button {
  height: 40px;
  font-weight: 600;
  margin: 0 auto;
  background: #148F2B;
  color: #fff;
  width: 200px;

  &:disabled {
    opacity: 0.4;
  }
}
