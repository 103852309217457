@import "assets/styles/reset.scss";

html {
  background: #fff;
  scroll-behavior: smooth;
}

body {
  font-family: sans-serif;
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
