.container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: var(--form3-card-radius);
  background: var(--form3-card-background);
}

.rowField {
  display: flex;
  gap: 16px;

  .date {
    width: 50%;
  }

  .cvc {
    width: 50%;
  }
}
